<template>
  <v-main class="">
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
  </v-main>
</template>

<script>
  export default {
    name: 'CoreView',
    metaInfo () {
      return {
        title: 'Asmodeus',
      }
    },
  }
</script>
